import React from 'react';
import Helmet from 'react-helmet';
import styles from '../scss/home.scss';
import {graphql, useStaticQuery} from 'gatsby';
import Img from "gatsby-image/withIEPolyfill";
import {get} from "lodash";
import Section from "../components/section/section.js";
import Card from "react-bootstrap/Card";
import Carousel from "react-bootstrap/Carousel";
import ReviewStars from "../components/review-stars/review-stars";
import TrustBox from "../components/trustpilot";
import Layout from "../components/layout";
import SEO from "../components/seo.js";

class Index extends React.Component {
  constructor(props) {
    super(props)
    this.reviews =  [
      {
        content: 'The entire process was very efficient and less time-consuming. My therapist did a great job in assisting me with my case and explaining the details.',
        stars: 5
      },
      {
        content: 'I couldn’t be happier with the customer service of CertaPet. They were very considerate and thorough in their evaluation and timely on getting me the paperwork required. I can’t thank you enough!',
        stars: 5
      },
      {
        content: 'The process was extremely easy, fast and convenient. Once approved I had my letter in a couple of days in my mailbox and same day in my email. I’m soo glad I have my pet as an emotional support animal. Now I don’t have to worry about being separated from him ever!',
        stars: 5
      }

    ]
  }

  render() {
    const images = {
      housing: get(this, 'props.data.housing'),
      housingWhite: get(this, 'props.data.housingWhite'),
      travel: get(this, 'props.data.travel'),
      travelWhite: get(this, 'props.data.travelWhite'),
      featuredLogos: get(this, 'props.data.featuredLogos.edges'),
      blockquote: get(this, 'props.data.blockquote.childImageSharp.fluid'),
    }

    const { data, children } = this.props

    return (
      <>
        <Layout containerClassNames="home-layout">
          <Section bgcolor="#fff" className="home-header-section">
            <div className="row">
              <h1 className='col-12 col-md-7 offset-md-2 home'>
                Real Therapists. Real Support.<br/> Real Comfort.
              </h1>
            </div>
          </Section>
          <Section bgcolor="#fff" className="section-one">
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-10 offset-md-1 mt-5 text-center">
                  <div className="content mt-5">
                    <p>Therapetic knows how important it is to have your pet by your side. <br/><br/>That’s why we offer a <strong>free and confidential screening test</strong> which brings you <br className='d-none d-md-block'/> that much closer to a <strong>Housing or Travel ESA letter</strong> issued by a <strong>licensed mental <br className='d-none d-md-block'/> health professional</strong>. And we’ll support you every step of the way. </p>
                  </div>
                </div>
                {/*<a href="https://www.certapet.com/get-started/?utm_source=therapetic.org&utm_medium=referral&utm_campaign=homepagev2&utm_content=cta1" target="_blank" className="my-4 mx-auto btn-cta text-center">BEGIN YOUR FREE SCREENING</a>*/}
                <a href="/screening" className="my-4 mx-auto btn-cta text-center">BEGIN YOUR FREE SCREENING</a>
              </div>
            </div>
          </Section>
          <Section bgcolor="#e8f4f4" className="section-common-questions section-three-steps">
            <div className="container">
              <div className="common-questions row">
                <div className="col-12 col-md-12">
                  <h2>Get Your ESA Letter In Three<br className='d-none d-md-block'/> Easy Steps</h2>
                  <div className="row">
                    <div className="col-12 col-md-4 text-center first-card">
                      <Card>
                        <Card.Header className="rounded-circle number">
                          1
                        </Card.Header>
                        <Card.Body>
                          <Card.Title as="h3" className="mb-4 mt-3">Your Free Screening Test</Card.Title>
                          <Card.Text>
                            All it takes is five minutes for<br/> you to find out if you qualify for<br/> an ESA Letter. It’s easy and<br/> confidential.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </div>
                    <div className="col-12 col-md-4 text-center first-card">
                      <Card>
                        <Card.Header className="rounded-circle number">
                          2
                        </Card.Header>
                        <Card.Body>
                          <Card.Title as="h3" className="mb-4 mt-3">Speak With A Licensed Therapist</Card.Title>
                          <Card.Text>
                            Each brief-yet-comprehensive <br/>consultation with a licensed <br/>mental health professional in <br/>your state takes about 15-30 <br/>minutes.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </div>
                    <div className="col-12 col-md-4 text-center first-card">
                      <Card>
                        <Card.Header className="rounded-circle number">
                          3
                        </Card.Header>
                        <Card.Body>
                          <Card.Title as="h3" className="mb-4 mt-3">Get Your ESA Letter</Card.Title>
                          <Card.Text>
                            And that’s all it takes for you to<br/> print your own letter, or have one<br/> shipped to you*.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-md-5">
                <a href="/screening" className="my-4 mx-auto btn-cta text-center">BEGIN YOUR FREE SCREENING</a>
              </div>
              <div className="row pb-5">
                <p className="my-4 mx-auto text-center">*Available in the US and Canada only</p>
              </div>
            </div>
          </Section>
          <Section bgcolor="#e0f0f0" className="review">
            <div className="row pt-5 pb-5">
              <h2 className="col-12 col-md-4 offset-md-4 mt-5">Our Customers Love <br className='d-md-none'/>  Us</h2>
            </div>
            <div className="row home-review-content">
              <div className="col-10 col-md-6 offset-md-3 offset-1 mb-2">
                We pride ourselves on providing support throughout the process.<br/> Which is why we’re #1 in the ESA Letter category.
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-10 offset-md-1 mt-5">
                <Carousel className="carousel-reviews text-left d-block d-md-none" controls={false}>
                  { this.reviews.map((review, index) => {
                      return (
                        <Carousel.Item className="reviews-slide" key={index}>
                          <div className="row">
                            <div className="col-12 col-md-4 text-center mt-3">
                              <Card>
                                <Card.Header>
                                  <Img fluid={images.blockquote} alt="Review Icon" className="img-fluid lazyload" />
                                </Card.Header>
                                <Card.Body>
                                  <Card.Text>
                                    {review.content}
                                  </Card.Text>
                                  <ReviewStars stars={review.stars} color="#2dbecc" />
                                </Card.Body>
                              </Card>
                            </div>
                          </div>
                        </Carousel.Item>
                      )
                    })}
                </Carousel>

                <Carousel className="carousel-reviews text-left d-none d-sm-none d-md-block" controls={false}>
                  <Carousel.Item className="reviews-slide">
                    <div className="row">
                      { this.reviews.map((review, index) => {
                        return (
                              <div className="col-12 col-md-4 text-center mt-3"  key={index}>
                                <Card>
                                  <Card.Header>
                                    <Img fluid={images.blockquote} alt="Review Icon" className="img-fluid lazyload" />
                                  </Card.Header>
                                  <Card.Body>
                                    <Card.Text>
                                      {review.content}
                                    </Card.Text>
                                    <ReviewStars stars={review.stars} color="#2dbecc" />
                                  </Card.Body>
                                </Card>
                              </div>
                        )
                      })}
                    </div>
                  </Carousel.Item>
                </Carousel>
              </div>

              <div className="container">
                <div className="row pt-5 mt-5 pb-5">
                  <a href="https://www.trustpilot.com/review/therapetic.org?utm_medium=trustbox&utm_source=MicroReviewCount" target="_blank" className="my-4 mx-auto btn-cta text-center">READ ALL REVIEWS</a>
                  <div className="col-12 pb-5">
                    <TrustBox templateId="5419b6a8b0d04a076446a9ad" businessUnitId="5a0093cb0000ff0005afb51b" theme="light" />
                  </div>
                </div>
              </div>
            </div>
          </Section>
          <Section bgcolor="#eaf5fa" className="section-second">
            <div className="section-second">
              <div className="row align-items-center">
                <div className="col-12 col-md-6">
                  <Img fluid={data.youngWomanWithHerEsaInArms.childImageSharp.fluid} alt="Young Woman With Her Esa In Arms" className="img-fluid lazyload" />
                </div>
                <div className="col-12 col-md-6 mb-5">
                  <div className="container">
                    <h2 className="col-12 col-md-12 mb-5 mt-5">About Therapetic</h2>
                  </div>
                  <div className="container">
                    <div className="content col-12 col-md-10">
                      <p>Traveling alone can be stressful. And a home just isn’t a home without your best friend. Therapetic understands the comfort and companionship that a pet can provide, and we don’t think you should have to go without it.</p>
                      <br/>
                      <p>That’s why we’re dedicated to helping you secure an ESA Letter, if you qualify, through a guided process that involves a free screening, a licensed therapist session and a customer support team that is dedicated to getting you all the information you need.</p>
                      <br/>
                      <p>We also work with CertaPet.com, the #1 Emotional Support Letter Service, to ensure that your letter is 100% compliant with state and federal regulations.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Section>
          <Section bgcolor="#eaf5fa" className="bgsection">
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-8 offset-md-2 mt-5 mb-md-5">
                  <div className="block pt-5 pb-5">
                  <div className="bgsection-img mb-3">
                    <Img fluid={data.housing.childImageSharp.fluid} alt="Housing" className="img-fluid lazyload" />
                  </div>
                  <h2 className="mb-5 mt-4">Fair Housing Laws and ESA for Housing</h2>
                  <div className="content">
                    <p>The FHA requires property owners to make reasonable accommodations to individuals with disabilities, including those who are accompanied by Emotional Support Animals (ESAs). We’ll help you secure a Housing ESA Letter is is 100% compliant with the FHA and Hud Laws.</p>
                  </div>
                </div>
                  <div className="block pt-5 pb-5">
                    <div className="bgsection-img mb-3">
                      <Img fluid={data.travel.childImageSharp.fluid} alt="Travel" className="img-fluid lazyload" />
                    </div>
                    <h2 className="mb-5 mt-4">Air Carrier Access Act and ESA Letter for Travel</h2>
                    <div className="content">
                      <p>Under the ACAA, if you’ve been diagnosed with a mental disability and have an emotional support animal letter from a mental health professional verifying the emotional benefit of the animal, you will be allowed to travel with your pet.</p>
                      <br/>
                      <p>Therapetic will get you through the process of getting your very own Travel ESA Letter that is 100% compliant with the Air Carrier Access Act and accepted by all airlines.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Section>
          <Section bgcolor="#e5f5fa" className="section-letter-option">
              <div className="row">
                <div className="col-12 col-md-10 offset-md-1 pb-5">
                  <h2 className="title mt-5 mb-5">The Most Affordable ESA Letter Option</h2>
                  <div className="row">
                    <div className="col-10 offset-1 content mb-5">
                      <p>We believe that those in need of an emotional support animal shouldn’t have to pay a fortune to make it a reality. Which is why Therapetic is the most affordable ESA letter service that also <strong>prioritizes compliance</strong> so you don’t run into any issues or conflicts.</p>
                    </div>

                    <div className="col-12 col-md-3 offset-md-1 text-center card-letter-option">
                      <div className="row">
                        <div className="col-4 col-md-5 img">
                          <Img fluid={images.housingWhite.childImageSharp.fluid} alt="Housing" className="img-fluid lazyload mt-md-5 mt-sm-1" />
                        </div>
                        <div className="col-7 col-md-7 text-left">
                          <div className="price">$149<sup><small> 99</small></sup></div>
                          <div className="description">for Housing ESA Letter</div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-3 text-center card-letter-option ml-md-5">
                      <div className="row">
                        <div className="col-4 col-md-5 img">
                          <Img fluid={images.travelWhite.childImageSharp.fluid} alt="Housing" className="img-fluid lazyload mt-md-5 mt-sm-1" />
                        </div>
                        <div className="col-7 col-md-7 text-left">
                          <div className="price">$149<sup><small> 99</small></sup></div>
                          <div className="description">for Travel ESA Letter</div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-3 text-center card-letter-option ml-md-5">
                      <div className="row">
                        <div className="col-6 col-md-5 img-xs pt-md-2">
                          <div className="row mx-auto mt-md-4">
                            <div className="mx-auto">
                              <Img fluid={images.housingWhite.childImageSharp.fluid} alt="Housing" className="img-fluid lazyload" />
                            </div>
                            <div className="mx-auto">
                              <Img fluid={images.travelWhite.childImageSharp.fluid} alt="Housing" className="img-fluid lazyload" />
                            </div>
                          </div>
                        </div>
                        <div className="col-4 col-md-7 text-left">
                          <div className="price">$199<sup><small> 99</small></sup></div>
                          <div className="description mb-md-4">for Both</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </Section>
          <Section bgcolor="#f3f3f3" className="section-as-seen-on">
            <div className="container">
              <div className="row text-center">
                <div className="col-md-12 col-12 mb-2">
                  <h2>As Seen On</h2>
                </div>
              </div>
              <div className="row">
                { images.featuredLogos.map(({node}, i) => {
                  return (
                    <div className="col-6 col-md-2 offset-3 offset-md-0 mt-5 ml-md-3" key={i}>
                      <Img alt={`Featured ${node.name}`}  fluid={node.childImageSharp.fluid} className="featured-logo"/>
                    </div>
                  )
                }) }
              </div>
            </div>
          </Section>
        </Layout>
      </>
    )
  }
}

export default Index

export const squareImage = graphql`
  fragment squareImage on File {
    childImageSharp {
      fluid(maxWidth: 720 ) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  fragment featuredLogo on File {
    childImageSharp {
      fluid(maxWidth: 180, maxHeight: 40) {
        ...GatsbyImageSharpFluid
      }      
      fixed(width: 180, height: 40) {
        ...GatsbyImageSharpFixed
      }
    }
  }
  fragment reviewAvatar on File {
    childImageSharp {
      fixed(width: 50, height: 50) {
        ...GatsbyImageSharpFixed
      }
    }
  }
`

export const pageQuery = graphql`
    query SectionEsaLetterOptionsQuery {
      housing: file(relativePath: { eq: "page_layout/icons/housing-icon.png" }) {
        childImageSharp {
          fluid(maxWidth: 161, maxHeight: 154) {
            ...GatsbyImageSharpFluid
          }
        }
      } 
      housingWhite: file(relativePath: { eq: "page_layout/icons/ico-housing-white.png" }) {
        childImageSharp {
          fluid(maxWidth: 161, maxHeight: 154) {
            ...GatsbyImageSharpFluid
          }
        }
      } 
      travel: file(relativePath: { eq: "page_layout/icons/travel-icon.png" }) {
        childImageSharp {
          fluid(maxWidth: 161, maxHeight: 154) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      travelWhite: file(relativePath: { eq: "page_layout/icons/ico-travel-white.png" }) {
        childImageSharp {
          fluid(maxWidth: 161, maxHeight: 154) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      featuredLogos: allFile(filter: {relativeDirectory: {eq: "home/featured"}}, sort: {order: ASC, fields: name}) {
        edges {
          node {
            ...featuredLogo
            name
          }
        }
      }
      blockquote: file(relativePath: { eq: "page_layout/icons/ico-blockquotes.png" }) {
        childImageSharp {
          fluid(maxWidth: 70, maxHeight: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      } 
      youngWomanWithHerEsaInArms: file(relativePath: { eq: "page_layout/young-woman-with-her-esa-in-arms.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 682, maxHeight: 622) {
            ...GatsbyImageSharpFluid
          }
        }
      }  
    }
  `





